/* Smartphones (portrait and landscape) ----------- */
@media screen and (min-device-width:320px) and (max-device-width:480px) {
	.none-480 {
		display: none !important;
		visibility:  hidden !important;
	}
}
/* Smartphones (landscape) ----------- */
@media screen and (min-width:321px) {
	/* Styles */
}
/* Smartphones (portrait) ----------- */
@media screen and (max-width:320px) {
	/* Styles */
}
/* iPads (portrait and landscape) ----------- */
@media screen and (min-device-width:768px) and (max-device-width:1024px) {
	/* Styles */
}
/* iPads (landscape) ----------- */
@media screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:landscape) {
	/* Styles */
}
/* iPads (portrait) ----------- */
@media screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:portrait) {
	/* Styles */
}
/* Smartphones General ----------- */
@media screen and (max-width:768px) {
	.none-768 {
		display: none !important;
		visibility:  hidden !important;
	}
	.negative-none-768 {
		display: unset !important;
		visibility:  unset !important;
	}
}
@media screen and (max-width:810px) {
	.none-810 {
		display: none !important;
		visibility:  hidden !important;
	}
}
/* Desktops and laptops ----------- */
@media screen and (max-width:1224px) {
	.none-1224 {
		display: none !important;
		visibility:  hidden !important;
	}
	.text-with-dots-header {
	    max-width: calc(100vw - 810px);
	    min-width: 40px;
	}
	.display-task-1224 {
		margin: 0 auto;
		width: 100%;
		max-width: none;
	}
	.fixed-corner-button{
		right: 15px !important;  
	}
}
@media screen and (max-width:960px) {
	.none-960 {
		display: none !important;
		visibility:  hidden !important;
	}
}
/* Large screens ----------- */
@media screen and (min-width:1824px) {
	/* Styles */
}
/* iPhone 4 - 5s ----------- */
@media screen and (-webkit-min-device-pixel-ratio:1.5) and (min-device-pixel-ratio:1.5) {
	/* Styles */
}
/* iPhone 6 ----------- */
@media screen and (max-device-width:667px) and (-webkit-device-pixel-ratio:2) {
	/* Styles */
}
/* iPhone 6+----------- */
@media screen and (min-device-width:414px) and (-webkit-device-pixel-ratio:3) {
	/*** You've spent way too much on a phone ***/
}
/* Samsung Galaxy S7 Edge ----------- */
@media screen and (-webkit-min-device-pixel-ratio:3) and (min-resolution:192dpi) and (max-width:640px) {
	/*
	*/
}