.stage-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 14px;
    padding-right: 22px;
    box-shadow: rgb(0 0 0 / 5%) 0px 2px 5px 0px, rgb(0 0 0 / 5%) 0px 0px 0px 1px;
    height:  54px;
}
.stage-header:hover > div.setting-stage button{
    display: block !important;
}
.stage-header:hover > input:focus + div.setting-stage button{
    display: none !important;
}
.stage-header > input:focus{
    color: rgba(var(--bs-secondary-rgb),var(--bs-text-opacity))!important;
    background: var(--bs-body-bg) !important;
}
.stage-header-icon {
    width: 36px;
    height: 36px;
    margin-right: 6px;
    padding: 6px;
}
.stage-header-icon svg {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-45px);
}
.modal-content{
    border-radius: 0!important;
}
.modal-maintask.modal-header div.modal-title{
    display: flex!important;
    grid-gap: .5rem!important;
    gap: .5rem!important;
    width: 96.6%!important;
}
.modal-maintask.modal-body{
    min-height: calc(100vh - 77px);
}
.action-task-detail > .react-datepicker-wrapper{
    display: flex!important;
}
.action-task-detail > .react-datepicker-wrapper .react-datepicker__input-container{
    padding-right: .5rem!important;
    padding-left: .5rem!important;
}
.css-1rhbuit-multiValue:has(.highlight-background) {
    background-color: rgba(228, 161, 27, 1) !important;
}
.kanban-container .stage-root:nth-child(odd) .stage-odd-even{
    /*background-color: lightgrey;*/
    /*linear-gradient( -45deg, rgb(245, 247, 248), rgb(237, 241, 242) 100%)*/
}
.kanban-container .stage-root:nth-child(even) .stage-odd-even {
    background-color: var(--bs-gray-100);
}
.open__chevron {
    border-radius: 2px;
    transition: transform 0.3s ease-in-out; 
}
.open__chevron.rotate {
    transform: rotate(180deg);
}
.fixed-corner-button {
    right: calc((100% - var(--bs-modal-width))/2 + 5px) !important;  
    top:77px !important;   
    z-index: 1000; 
    border-radius: 5px; 
    cursor: pointer;
    padding:2px !important;
}
