/* font-size --fsi is important */

/* pixel height --pix-hi is important*/

/* pixcel width --pix-wi is important*/

/* percent width --per-wi is important*/

/* texti is important */

:root {
    /* font-size */
    --fs-9: 9px;
    --fsi-9: 9px !important;
    --fs-11: 11px;
    --fsi-11: 11px !important;
    --fs-12: 12px;
    --fsi-12: 12px !important;
    --fs-13: 13px;
    --fsi-13: 13px !important;
    --fs-14: 14px;
    --fsi-14: 14px !important;
    --fs-15: 15px;
    --fsi-15: 15px !important;
    --fs-16: 16px;
    --fsi-16: 16px !important;
    --fs-18: 18px;
    --fsi-18: 18px !important;
    --fs-22: 22px;
    --fsi-22: 22px !important;
    --fs-24: 24px;
    --fsi-24: 24px !important;
    /* pixel height */
    --pix-h-24: 24px;
    --pix-hi-24: 24px !important;
    --pix-h-25: 25px;
    --pix-hi-25: 25px !important;
    --pix-h-27: 27px;
    --pix-hi-27: 27px !important;
    --pix-h-29: 29px;
    --pix-hi-29: 29px !important;
    --pix-h-30: 30px;
    --pix-hi-30: 30px !important;
    --pix-h-31: 31px;
    --pix-hi-31: 31px !important;
    --pix-h-32: 32px;
    --pix-hi-32: 32px !important;
    --pix-h-33: 33px;
    --pix-hi-33: 33px !important;
    --pix-h-40: 40px;
    --pix-hi-40: 40px !important;
    --pix-h-45: 45px;
    --pix-hi-45: 45px !important;
    --pix-h-90: 90px;
    --pix-hi-90: 90px !important;
    --pix-h-150: 150px;
    --pix-hi-150: 150px !important;
    --pix-h-120: 120px;
    --pix-hi-120: 120px !important;
    --pix-h-180: 180px;
    --pix-hi-180: 180px !important;
    --pix-h-200: 200px;
    --pix-hi-200: 200px !important;
    --pix-h-210: 210px;
    --pix-hi-210: 210px !important;
    --pix-h-250: 250px;
    --pix-hi-250: 250px !important;
    /* pixcel width */
    --pix-w-24: 24px;
    --pix-wi-24: 24px !important;
    --pix-w-25: 25px;
    --pix-wi-25: 25px !important;
    --pix-w-27: 27px;
    --pix-wi-27: 27px !important;
    --pix-w-29: 29px;
    --pix-wi-29: 29px !important;
    --pix-w-30: 30px;
    --pix-wi-30: 30px !important;
    --pix-w-31: 31px;
    --pix-wi-31: 31px !important;
    --pix-w-32: 32px;
    --pix-wi-32: 32px !important;
    --pix-w-33: 33px;
    --pix-wi-33: 33px !important;
    --pix-w-40: 40px;
    --pix-wi-40: 40px !important;
    --pix-w-45: 45px;
    --pix-wi-45: 45px !important;
    --pix-w-72: 72px;
    --pix-wi-72: 72px !important;
    --pix-w-80: 80px;
    --pix-wi-80: 80px !important;
    --pix-w-82: 82px;
    --pix-wi-82: 82px !important;
    --pix-w-150: 150px;
    --pix-wi-150: 150px !important;
    --pix-w-180: 180px;
    --pix-wi-180: 180px !important;
    --pix-w-200: 200px;
    --pix-wi-200: 200px !important;
    --pix-w-210: 210px;
    --pix-wi-210: 210px !important;
    --pix-w-240: 240px;
    --pix-wi-240: 240px !important;
    --pix-w-250: 250px;
    --pix-wi-250: 250px !important;
    --pix-w-270: 270px;
    --pix-wi-270: 270px !important;
    --pix-w-300: 300px;
    --pix-wi-300: 300px !important;
    --pix-w-308: 308px;
    --pix-wi-308: 308px !important;
    --pix-w-328: 328px;
    --pix-wi-328: 328px !important;
    --pix-w-320: 320px;
    --pix-wi-320: 320px !important;
    --pix-w-330: 330px;
    --pix-wi-330: 330px !important;
    /* percent width */
    --per-w-66: 66%;
    --per-wi-66: 66% !important;
    --per-w-34: 34%;
    --per-wi-34: 34% !important;
    /* pixcel margin */
    --pix-m-2: 2px;
    --pix-mi-2: 2px !important;
    /* text */
    --text-justify: justify;
    --texti-justify: justify !important;
}