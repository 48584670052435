/*Leftnav*/
.logo{
    height: 60px !important;
}
#wrapper.overflow-hidden.toggled a#menu-toggle-close{
    display: none !important;
}
#wrapper.overflow-hidden:not(.toggled) a#menu-toggle-open{
    display: none !important;   
}
.nav-items > div.accordion-body{
    padding: 0.5rem !important;
}
.nav-items > div.accordion-body button{
    font-size: 13px !important;
}
.nav-header button.accordion-button{
    border-radius: 0 !important;
    padding: 0.475rem 0.75rem !important;
    background: var(--bs-gray-800);
    color: var(--bs-white);
    font-weight: var(--fa-font-light);
    border-color: var(--bs-btn-border-color) !important;
}
.nav-header button.accordion-button:after{
    filter: brightness(0) invert(1);
}
.nav-header button.accordion-button:focus,
.nav-header button.accordion-button:hover{
    box-shadow: none !important;
    opacity: 0.75 !important;
    border-color: var(--bs-btn-active-border-color) !important;
}
.nav-content{
    max-height: calc(100vh - 193px);
    overflow-y: scroll;
}
#wrapper {
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
#wrapper.toggled {
    padding-left: 320px;
}
#sidebar-wrapper {
    z-index: 1000;
    position: fixed;
    left: 320px;
    width: 0;
    height: 100%;
    margin-left: -322px;
    overflow-y: auto;
    overflow-x: hidden;
    background: #DB1849;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
#sidebar-wrapper {
    width: 320px;
}
#wrapper {
    padding-left: 320px;
}
#wrapper.toggled {
    padding-left: 0;
}
#wrapper.toggled #sidebar-wrapper {
    width: 0;
}
#wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
}
#page-content-wrapper {
    /*padding: 20px;*/
    position: relative;
}