.right-container {
    max-height: 100%;
    width: var(--pix-w-72);
    z-index: 10;
}
.right-container > div.list-group {
    height: calc(100vh - 59px);
    overflow-y: auto;
}
.panel-activity-filter {
    right: calc(100%);
    display: flex;
    flex-direction: column;
}
.panel-activity-filter div.toast-body{
    overflow: auto;
    height: calc(100vh - 100px);
}