/*datepicker*/
/*nav link*/
.nav-tabs .nav-link {
    cursor: pointer;
}
/*=================*/
/*datepicker*/
.input-datepicker {
    font-size: var(--fs-9);
    height: var(--pix-h-29);
}
.input-datepicker:hover:not(:focus) {
    cursor: pointer;
}
.react-datepicker__close-icon::after {
    background-color: var(--bs-blue);
}
/*react-select*/
.cuson-selects-value {
    /*height: 100% !important;*/
    display: grid;
    z-index: 10;
}
.cuson-selects-value > div > div {
    /*padding: 0 !important;*/
}
.cuson-selects-value > div > div > div {
    cursor: pointer;
}
.cuson-selects-value > div > div:first-of-type,
.cuson-selects-value > div > div > div,
.cuson-selects-value > div + div * {
    cursor: pointer;
    font-size: var(--fs-13);
}
.cuson-tags-value > div + div{
    margin-top: 0 !important;
}
.cuson-tags-value > div > div:first-of-type,
.cuson-tags-value > div > div > div,
.cuson-tags-value > div + div * {
    cursor: pointer;
    font-size: var(--fs-13);
}
.cuson-tags-value > div > div{
    grid-gap: .25rem!important;
    gap: .25rem!important;
    padding-right: .25rem!important;
    padding-left: .25rem!important;
    cursor: pointer;
}
.cuson-tags-value > div > div > div > div{
    padding-top: .125rem!important;
    padding-bottom: .125rem!important;
}
.cuson-watches-value > div > div:first-of-type,
.cuson-watches-value > div > div > div,
.cuson-watches-value > div + div * {
    cursor: pointer;
}
/*nav link*/
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    --mdb-nav-pills-link-active-bg: #e3ebf7;
    --mdb-nav-pills-link-active-color: #285192;
    background-color: var(--mdb-nav-pills-link-active-bg) !important;
    color: var(--mdb-nav-pills-link-active-color) !important;
}
/*scroller*/
.work-scroller {
    -webkit-overflow-scrolling: auto !important;
    --scrollbar-color-thumb: #00000033;
    --scrollbar-color-track: transparent;
    --scrollbar-width: thin;
    --scrollbar-width-legacy: 0.75rem;
    -webkit-overflow-scrolling: auto !important;
    scrollbar-color: var(--scrollbar-color-thumb) var(--scrollbar-color-track);
    scrollbar-width: var(--scrollbar-width);
}
.work-scroller::-webkit-scrollbar-thumb {
    background: transparent;
}
.work-scroller::-webkit-scrollbar-track {
    background: transparent;
}
.work-scroller:hover::-webkit-scrollbar-thumb {
    background: var(--scrollbar-color-thumb);
}
.work-scroller:hover::-webkit-scrollbar-track {
    background: var(--scrollbar-color-track);
}
.work-scroller::-webkit-scrollbar {
    max-width: var(--scrollbar-width-legacy);
    max-height: var(--scrollbar-width-legacy);
}
.nav-color-scroller, .right-color-scroller, .filter-color-scroller {
    --scrollbar-color-thumb: var(--bs-gray-800);
}
/*react-draft-wysiwyg*/
.checklist-content .DraftEditor-root{
    min-height: 36px;
    display: flex !important;
    align-items: center !important;
}
.described-comment .DraftEditor-root{
    /*min-height: 85px;
    display: flex !important;
    align-items: center !important;*/
}
.public-DraftStyleDefault-block {
    margin: 0px 0px !important;
}
.DraftEditor-editorContainer {
    width: 100%;
}
div.editor-class.checklist-content .rdw-suggestion-dropdown ,
div.editor-class.described-comment .rdw-suggestion-dropdown {
    -webkit-overflow-scrolling: auto !important;
    --scrollbar-color-thumb: #00000033;
    --scrollbar-color-track: transparent;
    --scrollbar-width: thin;
    --scrollbar-width-legacy: 0.75rem;
    -webkit-overflow-scrolling: auto !important;
    scrollbar-color: var(--scrollbar-color-thumb) var(--scrollbar-color-track);
    scrollbar-width: var(--scrollbar-width);
    position: relative !important;
    cursor: pointer;
    max-width: calc(100% - 50px);
}
div.editor-class.checklist-content .rdw-suggestion-dropdown::-webkit-scrollbar-thumb ,
div.editor-class.described-comment .rdw-suggestion-dropdown::-webkit-scrollbar-thumb {
    background: transparent;
}
div.editor-class.checklist-content .rdw-suggestion-dropdown::-webkit-scrollbar-track ,
div.editor-class.described-comment .rdw-suggestion-dropdown::-webkit-scrollbar-track {
    background: transparent;
}
div.editor-class.checklist-content .rdw-suggestion-dropdown:hover::-webkit-scrollbar-thumb ,
div.editor-class.described-comment .rdw-suggestion-dropdown:hover::-webkit-scrollbar-thumb {
    background: var(--scrollbar-color-thumb);
}
div.editor-class.checklist-content .rdw-suggestion-dropdown:hover::-webkit-scrollbar-track ,
div.editor-class.described-comment .rdw-suggestion-dropdown:hover::-webkit-scrollbar-track {
    background: var(--scrollbar-color-track);
}
div.editor-class.checklist-content .rdw-suggestion-dropdown::-webkit-scrollbar ,
div.editor-class.described-comment .rdw-suggestion-dropdown::-webkit-scrollbar {
    max-width: var(--scrollbar-width-legacy);
    max-height: var(--scrollbar-width-legacy);
}
div.editor-class.checklist-content .rdw-suggestion-dropdown > span:hover,
div.editor-class.described-comment .rdw-suggestion-dropdown > span:hover{
    background-color: #F1F1F1;
}
.dangerouslySetInnerHTML span > p{
    margin-bottom: 0 !important;
}